<template>
  <div class="item" @click="onClick" :class="classObj">
    <div>
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

    selectedDepartMent: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    classObj() {
      return this.selectedDepartMent.name === this.item.name ? "active" : "";
    },
  },
  methods: {
    onClick() {
      this.$emit("setSelectedDepartMent", this.item);
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.item > div {
  flex: 1;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item:hover,
.active {
  background-color: #e6f7ff;
  color: #1890ff;
}
</style>