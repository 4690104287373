<template>
  <div class="group">
    <div class="left group-title">
      <template v-if="!editStatus">
        <div class="title">
          {{ item.majorGroup }}
        </div>
        <div class="ratio">
          (
          {{ item.majorGroupQuota }}% / <Money :money="groupMoney" />万元)
        </div>
        <div v-if="phase.configurable">
          <a-icon
            type="edit"
            class="edit"
            style="margin-right: 4px"
            @click="edit"
          />
          <a-icon
            type="delete"
            class="edit"
            @click="remove"
            v-if="index !== 0 || item.list.length === 0"
          />
        </div>
      </template>
      <template v-else>
        <a-input
          v-model="item.majorGroup"
          style="width: 120px; margin-right: 8px"
          size="small"
        />
        <a-input-number
          v-model="item.majorGroupQuota"
          :formatter="$ratioFormatter"
          :min="0.01"
          :max="100"
          style="width: 64px; margin-right: 8px"
          size="small"
        />

        <a-icon type="check" class="edit" @click="save" />
      </template>
    </div>

    <div v-if="item.list.length > 0">
      <Item
        v-for="(element, idx) in item.list"
        :key="element.type"
        :item="element"
        :majorGroup="item.majorGroup"
        :majorGroupQuota="item.majorGroupQuota"
        :money="groupMoney * element.allocateQuota * 0.01"
        :index="idx"
        :list="item.list"
        :phase="phase"
        :phaseIndex="phaseIndex"
      />
    </div>
    <div v-else>
      <a-empty />
    </div>
  </div>
</template>

<script>
import Item from "./phase-item.vue";
export default {
  props: {
    phase: {
      type: Object,
      default: () => ({}),
    },
    phaseIndex: {
      type: Number,
    },

    // 子项
    item: {
      type: Object,
      default: () => ({}),
    },
    // 当前子项的金额 = 专业剩余金额 * 当前子项的比例
    groupMoney: {
      type: Number,
      default: 0,
    },

    index: {
      type: Number,
      default: 0,
    },
    majorGroupList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Item,
  },

  data() {
    return {
      editStatus: false,
    };
  },

  methods: {
    edit() {
      this.editStatus = true;
    },
    save() {
      this.editStatus = false;
    },

    remove() {
      this.majorGroupList.splice(this.index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.group {
  padding: 12px;
  margin-bottom: 12px;
  background-color: #fff;
  .group-title {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 12px;
  }

  .left {
    margin-bottom: 12px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #1890ff;
      margin-right: 4px;
    }
    .ratio {
      margin-right: 8px;
    }
    .edit {
      &:hover {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
}
</style>