<template>
  <div>
    <div @click="add">
      <slot />
    </div>

    <a-modal
      :title="title"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="700px"
    >
      <a-input-search
        placeholder="请输入部门/姓名/拼音/联系方式搜索"
        enter-button
        @search="onSearch"
      ></a-input-search>

      <template v-if="selectedList.length > 0">
        <div class="title">
          <span>已选择</span>
          <a-icon
            type="close"
            class="refresh"
            @click="clearSelected"
            title="点我清空已选择"
          />
        </div>
        <a-space class="recent-selected-list">
          <a-tag
            color="blue"
            style="margin-bottom: 8px"
            v-for="item in selectedList"
            :key="item.id"
            :closable="true"
            @close="removeSelected(item)"
          >
            {{ item.name }}
          </a-tag>
        </a-space>
      </template>

      <template v-if="recentSelectedList.length > 0">
        <div class="title">
          <span>最近选择</span>
          <a-icon
            type="close"
            class="refresh"
            @click="clearRecentSelected"
            title="点我清空最近选择"
          />
        </div>
        <a-space class="recent-selected-list">
          <a-tag
            color="blue"
            style="margin-bottom: 8px"
            v-for="item in recentSelectedList"
            :key="item.id"
            :closable="true"
            @close="removeRecent(item)"
            @click="clickRecent(item)"
          >
            {{ item.name }}
          </a-tag>
        </a-space>
      </template>

      <Padding />

      <a-row :gutter="16">
        <a-col :span="8">
          <div class="refresh-wrapper">
            <div class="item">部门</div>
            <div class="sort">
              <a-dropdown>
                <a-icon type="setting" class="refresh" />

                <a-menu slot="overlay">
                  <a-menu-item v-for="item in deptSortList" :key="item.value">
                    <a href="#" @click="changeSortType(item.value)">{{
                      item.name
                    }}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
          <div class="list">
            <DepartmentItem
              v-for="item in departmentList"
              :key="item.id"
              :item="item"
              :selectedDepartMent="selectedDepartMent"
              @setSelectedDepartMent="setSelectedDepartMent"
            />
          </div>
        </a-col>

        <a-col :span="16">
          <div class="refresh-wrapper">
            <div class="item">姓名</div>
            <div class="item">联系方式</div>

            <a-tooltip placement="top" title="刷新">
              <a-icon
                type="sync"
                class="refresh"
                @click="getList"
                :spin="loading"
              />
            </a-tooltip>
          </div>

          <div class="list">
            <Item
              v-for="item in searchList"
              :key="item.id"
              :item="item"
              :single="single"
              :selectedList="selectedList"
              @setSelectedList="setSelectedList"
            />
          </div>
        </a-col>
      </a-row>

      <div class="right" style="margin-top: 24px">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="confirm">确定</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DepartmentItem from "./department-item.vue";
import Item from "./item";
export default {
  components: {
    DepartmentItem,
    Item,
  },

  props: {
    title: {
      type: String,
      default: "选择员工",
    },
    single: {
      type: Boolean,
      default: true, // 是否单选
    },
    // 默认值
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
      searchValue: null,

      selectedDepartMent: {}, // 选中的部门
      selectedList: [], // 选中的员工
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    // 可以进行排序的字段
    deptSortList() {
      return this.findDataDict("orgSort");
    },

    ...mapState("employeeSelector", [
      "loading",
      "list",
      "recentSelectedList",
      "sortType",
    ]),

    // 过滤出所有的部门
    departmentList() {
      const newList = [...this.list.filter((item) => item.employeeOrgVo)];

      if (newList.length === 0) {
        return newList;
      }
      if (!newList[0].employeeOrgVo) {
        return newList;
      }
      if (typeof newList[0].employeeOrgVo[this.sortType] !== "number") {
        return newList;
      }

      newList.sort((x, y) => {
        if (x.employeeOrgVo && y.employeeOrgVo) {
          if (x.employeeOrgVo[this.sortType] < y.employeeOrgVo[this.sortType]) {
            return -1;
          } else if (
            x.employeeOrgVo[this.sortType] > y.employeeOrgVo[this.sortType]
          ) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });
      const pureData = newList
        .map((item) => item.deptUniqueName)
        .filter((item, index, self) => item && self.indexOf(item) === index)
        .map((item) => {
          return {
            name: item,
          };
        });

      if (this.searchValue && this.searchValue.trim()) {
        const value = this.searchValue.trim();
        const index = pureData.findIndex(
          (item) => item.name.indexOf(value) > -1
        );
        if (index > -1) {
          return pureData.filter((item) => item.name.indexOf(value) > -1);
        } else {
          return pureData;
        }
      } else {
        return pureData;
      }
    },

    // 过滤出选中部门下的员工列表
    filterList() {
      let arr = [];

      if (this.selectedDepartMent.name) {
        arr = this.list.filter(
          (item) => item.deptUniqueName === this.selectedDepartMent.name
        );
      }
      return arr;
    },

    searchList() {
      let arr = [];
      if (this.searchValue && this.searchValue.trim()) {
        const searchValue = this.searchValue.trim();
        for (let i = 0; i < this.list.length; i++) {
          const item = this.list[i];
          // 拼音
          if (item.code && item.code.indexOf(searchValue) > -1) {
            arr.push(item);
          }
          // 拼音简拼
          if (item.firstLetter && item.firstLetter.indexOf(searchValue) > -1) {
            arr.push(item);
          }
          if (item.name && item.name.indexOf(searchValue) > -1) {
            arr.push(item);
          }
          if (item.mobile && item.mobile.indexOf(searchValue) > -1) {
            arr.push(item);
          }
        }
      }

      return arr.length > 0 ? arr : this.filterList;
    },
  },

  watch: {
    // 默认选中第一条部门记录
    departmentList(newValue) {
      if (newValue.length > 0) {
        this.selectedDepartMent = newValue[0];
      }
    },
    // 监听打开弹窗
    visible(newValue) {
      if (newValue) {
        // 如果没数据要刷新数据
        if (this.list.length === 0) {
          this.getList(); // 获取人员选择器数据
        }

        // this.selectedList = this.value; // 设置默认选中
        if (this.departmentList.length > 0) {
          this.selectedDepartMent = this.departmentList[0]; // 设置默认选中部门
        }
      }
    },
  },

  methods: {
    ...mapMutations("employeeSelector", [
      "setRecentSelectedList",
      "setSortType",
    ]),
    ...mapActions("employeeSelector", ["getList"]),

    add() {
      if (!this.disabled) {
        this.visible = true;
      }
    },

    clearRecentSelected() {
      this.setRecentSelectedList([]);
    },
    // 移除某一项最近选择
    removeRecent(item) {
      const list = this.recentSelectedList;
      const index = list.findIndex((element) => element.id === item.id);
      list.splice(index, 1);
      this.setRecentSelectedList(list);
    },
    // 点击某一项最近选择
    clickRecent(item) {
      // 单选
      if (this.single) {
        this.setSelectedList([item]);
      } else {
        const list = this.selectedList;
        const index = list.findIndex((element) => element.id === item.id);
        if (index === -1) {
          this.setSelectedList([...list, item]);
        }
      }
    },

    // 清空已选择
    clearSelected() {
      this.selectedList = [];
    },
    // 移除已选择的员工
    removeSelected(item) {
      const index = this.selectedList.findIndex(
        (element) => element.id === item.id
      );
      this.selectedList.splice(index, 1);
    },

    cancel() {
      this.selectedList = [];
      this.visible = false;
    },

    confirm() {
      this.$emit(
        
        "change",
        JSON.parse( JSON.stringify(this.selectedList)),
      );
      this.visible = false;
    },

    // 改变部门排序
    changeSortType(value) {
      this.setSortType(value);
    },
    // 选中部门
    setSelectedDepartMent(item) {
      this.selectedDepartMent = item;
    },
    setSelectedList(list) {
      this.selectedList = list;
      console.log("single", this.single);
      if (this.single) {
        this.$emit("change", this.selectedList);
        this.visible = false;
      }
    },
    // 关闭弹窗
    setVisible(visible) {
      this.visible = visible;
    },

    onSearch(value) {
      this.searchValue = value;
    },
  },
};
</script>

<style lang="less" scoped>
// 最近选择
.title {
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recent-selected-list {
  display: flex;
  flex-wrap: wrap;
}

// 刷新按钮
.refresh-wrapper {
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #f0f0f0;
  display: flex;
  position: relative;

  .item {
    flex: 1;
    padding: 8px;
  }

  .refresh {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    &:hover {
      color: #1890ff;
    }
  }
}

.list {
  height: 320px;
  overflow-y: auto;
  border: 1px solid #f0f0f0;
}
</style>