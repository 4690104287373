<template>
  <a-modal :visible="visible" title="发起审批" @ok="ok" @cancel="cancel">
    <template v-if="title">
      <a-alert :message="title" type="warning" show-icon />

      <Padding />
    </template>

    <a-textarea
      placeholder="请输入审批意见"
      v-model="comment"
      :auto-size="{ minRows: 3 }"
    />
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      comment: "",
    };
  },
  methods: {
    ok() {
      this.$emit("setVisible", false);
      this.$emit("done", this.comment);
    },
    cancel() {
      this.$emit("setVisible", false);
    },
  },
};
</script>