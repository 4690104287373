// 判断项目阶段子类型或专业是否选择员工
export function hasEmployee(list) {
    // 判断依据：没有 userId 的记录为未选员工
    const length = list.filter(item => item.userId).length
    return list.length === length;
}

// 判断项目阶段子类型或专业填写的比例或数值加起来是否在正确数值之内
export function validateMoney(amount, list) {
    let money = 0;
    list.forEach(staff => {
        if (staff.allocateUnit === '%') {
            money += amount * staff.allocateRatio * 0.01
        } else {
            money += staff.allocateRatio * 10000
        }
    })

    console.log('amount,money', amount, money)

    return Math.abs(amount - money) < 0.01
}

export function getRoleRatio(role, staffList) {

    const obj = [{
        value: 'major_charger',
        ratio: 0.1,
    }, {
        value: 'design',
        ratio: 0.6,
    }, {
        value: 'recheck',
        ratio: 0.15,
    }, {
        value: 'approval',
        ratio: 0.05,
    }].find(item => item.value === role)
    if (obj) {
        return obj.ratio;
    } else {
        if (staffList.filter((item) => item.role === "approval").length > 0) {
            return 0.1
        } else {
            return 0.15
        }
    }
}

// 判断专业下的角色固定比例下，每个人的钱是不是算得对
// 专业负责人 major_charger 10%
// 设计 design 60%
// 复核 recheck 15%
// 审核 audit 10%（若无审定，则加5%）
// 审定 approval 5%（可选）
export function validateRoleMoney(amount, staffList) {
    let message = '';

    const roleTypeList = [
        {
            name: '专业负责',
            value: 'major_charger'
        }, {
            name: '设计',
            value: 'design'
        }, {
            name: "复核",
            value: 'recheck'
        }, {
            name: "审核",
            value: "audit"
        }, {
            name: "审定",
            value: "approval"
        }
    ]

    roleTypeList.map(roleObj => {
        const filteredList = staffList.filter(item => item.role === roleObj.value)
        if (filteredList.length > 0) {
            if (roleObj.value === 'major_charger') {
                console.log('专业负责', amount, filteredList)
                if (!validateMoney(amount * 0.1, filteredList)) {
                    message = roleObj.name;
                }
            }
            if (roleObj.value === 'design') {
                if (!validateMoney(amount * 0.6, filteredList)) {
                    message = roleObj.name;
                }
            }
            if (roleObj.value === 'recheck') {
                if (!validateMoney(amount * 0.15, filteredList)) {
                    message = roleObj.name;
                }
            }
            if (roleObj.value === 'audit') {

                const approvalList = staffList.filter(item => item.role === 'approval')
                if (approvalList.length > 0) {
                    if (!validateMoney(amount * 0.1, filteredList)) {
                        message = roleObj.name;
                    }
                    if (!validateMoney(amount * 0.05, approvalList)) {
                        message = roleTypeList.find(roleItem => roleItem.value === 'approval').name
                    }
                } else {
                    if (!validateMoney(amount * 0.15, filteredList)) {
                        message = roleObj.name;
                    }
                }


            }

        }
    })

    console.log('return msg ', message)

    return message;

}


export function division(money) {
    const result = money / 10000;
    const arr = String(result).split(".");
    if (arr.length > 1) {
        if (arr[1].length <= 6) {
            return result;
        } else {
            return Number(result.toFixed(6));
        }
    } else {
        // 整数
        return result;
    }
}