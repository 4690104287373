<template>
  <div
    class="item"
    @click="onClick"
    :class="
      selectedList.findIndex((element) => item.id === element.id) > -1
        ? 'active'
        : ''
    "
  >
    <div>
      {{ item.name }}
    </div>
    <div>
      {{ item.mobile }}
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    single: {
      type: Boolean,
      default: true,
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("employeeSelector", ["recentSelectedList"]),
  },
  methods: {
    ...mapMutations("employeeSelector", ["setRecentSelectedList"]),
    onClick() {
      // 添加到最近选中
      if (
        this.recentSelectedList.findIndex(
          (item) => item.id === this.item.id
        ) === -1
      ) {
        this.setRecentSelectedList([...this.recentSelectedList, this.item]);
      }
      // 如果是单选
      if (this.single) {
        this.$emit("setSelectedList", [this.item]);
      } else {
        const list = this.selectedList;
        const index = list.findIndex((item) => item.id === this.item.id);
        if (index === -1) {
          this.$emit("setSelectedList", [...list, this.item]);
        } else {
          list.splice(index, 1);
          this.$emit("setSelectedList", [...list]);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.item > div {
  flex: 1;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item:hover,
.active {
  background-color: #e6f7ff;
  color: #1890ff;
}
</style>